@import '../../styles/shared';

.card {
  width: 100%;
  min-height: 249px;

  padding-bottom: 10px;

  overflow: hidden;

  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #b6b7cb;
  background-color: var(--color-white);
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
}

.card__checked {
  .btn__status {
    color: var(--color-white);

    border-radius: 16px;
    background-color: var(--primary-color);

    @include mazars {
      background-color: var(--color-teal-default);
    }

    @include aes {
      border-radius: 0;
      background-color: var(--color-red-strong);
    }

    @include gandh {
      background-color: var(--color-green-default);
    }
  }

  .tik {
    color: var(--color-white);
    font-size: 16px;

    margin-right: 6px;

    display: inline-block;
  }
}

.image {
  width: 100%;
  height: 149px;
  min-height: 149px;

  object-fit: cover;
}

.figcaption {
  position: relative;
  margin-top: -16px;
}

.wrapper__btn {
  padding: 0 16px;
}

.wrapper__image {
  position: relative;
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.captionWrapper {
  padding: 0 16px;
  margin-bottom: 24px;
}

.btn__status {
  @extend %align-center;
  font-size: 12px;
  color: var(--color-grey-default);

  width: 100%;
  height: 32px;

  padding: 0;
  margin-bottom: 23px;

  border-radius: 4px;
  background-color: var(--color-yellow-default);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @include aes {
    color: var(--color-white);
    background-color: var(--color-black);
  }

  @include gandh {
    background-color: #d2b464;
    color: var(--color-black);
  }
}

.tik {
  display: none;
}

.price,
.description,
.name {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  text-align: center;
  line-height: 18px;
  -webkit-line-clamp: 2;
}

.description {
  font-size: 12px;
  color: #676883;
}

.name {
  color: #000000;
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: 6px;
}

.price {
  color: #000000;
  font-weight: bold;
  font-size: 16px;

  margin-top: 20px;
}

.card__withPrice .descriptionWrapper {
  min-height: 49px;
}
