@import '../../styles/shared';

.container {
  @extend %align-center;

  position: relative;

  width: 100%;
  height: 100%;
  min-height: 100px;
}

.fullscreen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 999;

  background-color: rgba(255, 255, 255, 0.7);
}

.contain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 100;
  background: rgba(#fff, 0.7);
}

.spinner {
  @extend %align-center;

  position: absolute;
  top: 50%;
  left: 50%;

  width: 64px;
  height: 64px;

  border: 5px solid var(--primary-color);
  border-bottom: 5px solid transparent;
  border-radius: 50%;

  animation: spin 1s ease infinite;

  &__inner {
    width: 32px;
    height: 32px;

    border: 5px solid transparent;
    border-top: 5px solid var(--primary-color);
    border-radius: 50%;

    animation: inner 1s ease infinite;
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes inner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-720deg);
  }
}

.tableSpinner {
  @extend %align-center;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(255, 255, 255, 0.7);
}

.dotsContainer {
  width: 32px;

  .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    background-color: var(--primary-color);
    animation: wave 1.1s linear infinite;

    &:nth-child(2) {
      animation-delay: -0.9s;
    }

    &:nth-child(3) {
      animation-delay: -0.7s;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: none;
  }

  30% {
    transform: translateY(-4px);
  }
}
