.wrapper__image {
  position: relative;
}

.room__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.sliderComponent.slider {
  margin: 0;
  padding: 0;

  & :global(.slick-arrow) {
    color: #fff !important;
    opacity: 0.7;
  }

  & :global(.slick-prev) {
    z-index: 1;
    left: 8px;
  }

  & :global(.slick-next) {
    z-index: 1;
    right: 8px;
  }
}

.spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
