.wrapper {
  padding: 24px;
}

.title {
  margin-bottom: 24px;
  font-size: 18px;
}

.info {
  font-size: 14px;
}

.btn {
  color: var(--accent-color);
}
