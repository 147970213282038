.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
}

.header {
  display: flex;
  align-items: center;
  padding: 24px 34px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.title {
  margin-right: auto;
  font-size: 21px;
  font-weight: bold;
  color: var(--color-black-light);
}

.buttonArrow {
  padding: 0;
  margin-right: 16px;
}

.iconArrow {
  font-size: 24px;
}

.controls {
  display: flex;
  gap: 8px;
}

.main {
  position: relative;
  padding: 34px;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 68px;
  margin-bottom: 34px;
}

.fieldset {
  position: relative;
  width: 100%;
}

.fieldsetLegend {
  font-weight: bold;
  margin-bottom: 20px;

  &__disabled {
    opacity: 0.4;
  }
}

.fieldsetLine {
  display: flex;
  gap: 24px;
  margin-bottom: 34px;
}

.formGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 34px 24px;
}

.formControl {
  width: 100%;
}

.formControl__postCode {
  width: 100px;
}

.additionalAddress {
  grid-column: span 2;
}

.select__title {
  font-size: 14px;
  margin-bottom: 8px;
}
