.productsSection {
  padding: 16px;
}

.product {
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-template-rows: 1fr 40px;
  padding-bottom: 8px;
  margin-bottom: 14px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);

  .counter {
    justify-content: space-around;
    width: 120px;
  }
}

.product__title,
.product__price {
  align-self: center;
}

.button__close {
  align-self: flex-start;
  justify-self: flex-end;
  height: auto;
  padding: 8px;
}
