@import 'src/styles/shared';

.types {
  display: flex;
  flex-shrink: 0;
  overflow: auto;
  @extend %scrollbar;

  &::-webkit-scrollbar {
    height: 5px;
  }
}

.type {
  flex-shrink: 0;
  margin-bottom: 3px;
  color: var(--tabs-textColor);
  opacity: 0.5;
  cursor: pointer;

  &:not(:last-of-type) {
    margin-right: 14px;
  }

  &__active {
    opacity: 1;
    box-shadow: 0 3px 0 0 var(--tabs-textColor);
  }
}

.typeBtn {
  height: auto;
  padding: 2px 6px;
}
