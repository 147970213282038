.controls {
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  background-color: var(--color-white);

  margin-bottom: 16px;
  padding: 0 24px;

  min-height: 64px;

  justify-content: space-between;
}

.icon {
  font-size: 48px;

  color: #b6b7c4;

  &:not(:last-of-type) {
    margin-right: 16px;
  }
}

.btn__pdf {
  padding: 0;
}

.btn:not(:last-of-type) {
  margin: 8px;
}

.wrapper__pdf {
  border: 2px solid #676883;
  border-radius: 5px;
  background-color: var(--color-white);
}

.frame {
  width: 100%;
  height: 100%;
}

.comment {
  padding: 16px;
  margin-bottom: 16px;

  border-radius: 4px;
  background: linear-gradient(180deg, #a82837 0%, #e2001a 100%);
  box-shadow: 0 4px 16px #00000029;

  text-align: left;
  font-size: 14px;
  color: #f3f6f9;
}

.comment__text {
  word-break: break-all;
}

.commentTitle {
  font-size: 16px;
  font-weight: bold;

  margin-bottom: 1em;
}
