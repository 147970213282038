.model {
  position: absolute;

  z-index: -1;
  margin: 0;
  padding: 0;

  visibility: hidden;
}

.checkbox {
  cursor: pointer;
  display: flex;
}

.svg {
  width: 1em;
  height: 1em;
  margin-right: 10px;
  flex-shrink: 0;

  border-radius: 2px;
  border: 3px solid rgba(191, 197, 210, 0.6);

  &:hover {
    border: 3px solid rgba(191, 197, 210, 1);
  }

  &.invalid {
    border: 3px solid var(--color-red);
  }
}

.tip {
  fill: none;
  visibility: hidden;
}

.model:checked + .svg__fill {
  background-color: var(--primary-color);
  border: none;

  & .tip {
    visibility: visible;
    stroke: var(--color-white);
  }
}
