.value {
  text-align: center;

  margin: 0 6px;
  width: 30px;

  border: none;
}

.value__readOnly {
  outline: none;
}

//.input {
//  position: relative;
//
//  &::after {
//    content: '';
//
//    width: 12px;
//    height: 100%;
//
//    position: absolute;
//    right: 0;
//
//    z-index: 1;
//    background-color: #ffffff;
//  }
//}

.btn {
  width: 24px;
  height: 24px;

  padding: 0;
}
