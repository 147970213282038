@import '../../../styles/shared';

.panel {
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  min-width: 570px;

  align-self: flex-start;

  :global(.caption) {
    align-items: center;

    height: 40px;

    padding: 0 16px;

    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.16);

    &:first-child {
      font-weight: bold;
    }

    @include aes {
      background-color: var(--color-white-smoke);
    }
  }

  :global(.caption__extra) {
    padding: 16px;

    font-size: 16px;
    font-weight: bold;
    color: #333333;

    background-color: #f3f6f9;
  }

  :global(.column) {
    &:nth-child(1) {
      color: #333333;
      font-size: 16px;
    }

    &:nth-child(n + 2) {
      align-self: center;
      justify-self: center;

      color: #676883;
      font-size: 14px;
    }
  }

  :global(.grid-row) {
    display: grid;
    grid-template-columns: 60% 10% 15% 15%;
  }

  @include respond-to(tablet) {
    min-width: 470px;
  }
}

.products__empty {
  height: 100%;
  @extend %align-center;

  flex-grow: 1;
}

.message {
  font-size: 18px;
  color: #b6b7cb;
  text-align: center;
}

.product {
  align-items: center;

  padding: 16px;

  font-size: 14px;
  color: #333333;

  box-shadow: 0 1px 0 0 #f3f6f9;
}

.squized__panel {
  :global(.grid-row) {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  :global(.column) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
