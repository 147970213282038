@import '../../styles/shared';

.notification {
  min-height: 72px;

  padding: 16px 40px 16px 16px;

  color: var(--color-white);
  font-size: 14px;
  word-break: break-word;
  overflow-wrap: break-word;

  border-radius: 4px;
  background-color: var(--color-red);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
}

.notification__warning {
  color: var(--color-white);
  background-color: var(--color-yellow-medium);
}

.notification__info {
  background-color: var(--primary-color);

  @include gandh {
    background-color: var(--color-green-default);
  }
}

.btnRow {
  display: flex;
  align-items: center;

  margin-top: 16px;
}

.notification .btn__accept {
  width: 32px;
  height: 32px;
  padding: 0;

  color: rgba(#fff, 0.7);
  font-size: 16px;

  border: 2px solid currentColor;
  border-radius: 4px;
  background: transparent;

  &:hover,
  &:focus {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

.notification .btn__show {
  @extend .btn__accept;

  display: inline-flex;
  align-items: center;

  width: auto;
  padding: 0 24px;

  margin-left: 16px;
}
