@import '../../styles/shared';

.sectionTitle {
  font-size: 21px;
  font-weight: bold;
  line-height: 33px;
  color: var(--color-white);

  @include aes {
    color: var(--color-black);
  }

  @include gandh {
    color: var(--accent-color);
    display: none;
  }

  @include ccsc {
    display: none;
  }

  @include sbi {
    display: none;
  }

  margin-left: 30px;
  margin-bottom: 16px;
}

.favoritesAndCalendarSection {
  @extend %align-center;

  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  padding: 24px 20px 100px 20px;
  min-height: 500px;

  background: url('../../assets/images/bg-bubbles.svg') right -60px no-repeat,
    linear-gradient(to bottom, #a82837, #e2001a);

  @include aes {
    background: linear-gradient(to bottom, #f3f6f9, #ffffff);
  }

  @include gandh {
    background: var(--color-white);
    min-height: 676px;
  }

  @include ccsc {
    background: var(--color-white);
  }

  @include sbi {
    background: var(--color-white);
  }
}

.dashboard {
  @include aes {
    background: var(--color-black);
  }

  @include gandh {
    overflow-x: hidden;
  }
}

.favoritesSection {
  flex-grow: 1;
  width: 1px;

  @include gandh {
    flex-grow: unset;
    height: 100%;
    width: 57%;
    margin: auto;
  }
}

.calendarSection {
  width: 33%;
  margin-left: 25px;
  padding: 22px 10px;
  border-radius: 10px;
  background-color: var(--dashboardCalendar-backgroundColor);

  @include gandh {
    width: 43%;
  }
}

.invoiceSection {
  margin-top: 3px;
  padding-left: 40px;
  @include gandh {
    background-color: var(--accent-color);
    position: relative;
  }
}

.wrapper {
  display: flex;
  align-items: center;

  padding-bottom: 24px;

  overflow: auto;

  @extend %scrollbar;
}

.invoices {
  display: flex;
  align-items: center;

  @include gandh {
    position: absolute;
    top: -83px;
    left: 267px;
    width: 100%;
    overflow: auto;
    padding-bottom: 25px;
  }
}

.link {
  color: var(--color-white);

  @include aes {
    color: var(--color-black);
  }

  @include ccsc {
    color: var(--color-black);
  }

  @include sbi {
    color: var(--color-black);
  }
}

.link__invoice:not(:last-of-type) {
  margin-right: 16px;
}

.statistic {
  li {
    color: var(--dashboardStatisticRow-color);
  }
}
