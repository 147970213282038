@import '../../styles/shared';

.profile {
  display: flex;
  align-items: center;
  align-self: stretch;

  padding-right: 34px;
  padding-left: 16px;

  border-left: 1px solid var(--color-white);

  @include ccsc {
    border-color: var(--color-blue-ccsc);
  }

  @include sbi {
    border-color: var(--color-orange-sbi);
  }
}

.name {
  margin-right: 10px;
}

.linkWrapper {
  @extend %align-center;
  color: var(--color-white);
  align-self: stretch;

  @include ccsc {
    color: var(--color-blue-ccsc);
  }

  @include sbi {
    color: var(--color-orange-sbi);
  }
}

.link {
  @extend %align-center;
  font-size: 24px;
  color: var(--color-white);

  &__active {
    color: var(--dark-link-active);

    @include ccsc {
      color: var(--color-blue-ccsc);
    }

    @include sbi {
      color: var(--color-orange-sbi);
    }
  }

  @include ccsc {
    color: var(--color-blue-ccsc);
  }

  @include sbi {
    color: var(--color-orange-sbi);
  }
}

.linkProfile {
  margin-right: 16px;
}

.icon {
  cursor: pointer;

  font-size: 24px;
}
