@import '../../../styles/shared';

.modal__wrap {
  top: unset;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.modal {
  position: relative;
  margin: auto;

  &:global {
    &.aes {
      .rc-dialog-content {
        background: var(--color-black-light);
      }
    }
  }

  :global {
    .rc-dialog-content {
      padding: 20px 60px 20px 24px;

      color: #f3f6f9;
      box-shadow: 0 2px 8px #00000029;
      border-radius: 4px 4px 0 0;
      background: linear-gradient(180deg, #a82837 0%, #e2001a 100%);
    }

    .rc-dialog-close-x:after {
      border-color: #fff;
    }

    .rc-dialog-close-x:before {
      border-color: #fff;
    }

    .rc-dialog-close:hover {
      opacity: 0.4;
    }
  }
}
