@import '../../../styles/shared';

.room {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: transparent;
  }

  &__typeActive {
    &:before {
      background-color: var(--accent-color);
    }
  }

  &__typeInfo {
    &:before {
      background-color: var(--light-title-color);
    }

    .room__title {
      color: var(--light-title-color);
      font-size: 18px;
    }
  }
}

.room__info {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 8px;
}

.room__title {
  margin-bottom: 4px;
  font-weight: bold;
}

.room__seating {
  font-size: 14px;
  color: var(--color-grey-medium);
}
