@import '../../styles/shared';

.grid__booking {
  flex-grow: 1;

  :global(.grid-row) {
    display: grid;
    grid-template-columns: repeat(4, 25%);
  }
}

.navigation {
  background: transparent linear-gradient(180deg, #a82837 0%, #e2001a 100%) 0 0
    no-repeat padding-box;

  min-height: 66px;

  @include mazars {
    background: linear-gradient(180deg, #f3f6f9 0%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
  }

  @include aes {
    background: linear-gradient(180deg, #f3f6f9 0%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
  }

  @include gandh {
    background: var(--color-white);
  }

  @include ccsc {
    background: var(--color-blue-ccsc);
  }

  @include sbi {
    background: var(--color-orange-sbi);
  }
}

.content {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  margin-top: -32px;
  padding: 0 34px 20px 34px;
}

.filters {
  display: flex;
  align-items: center;

  padding: 22px 0 48px 34px;

  color: var(--color-white);

  @include aes {
    color: var(--color-black);
  }

  @include gandh {
    color: var(--color-black);
  }
}

.select {
  min-width: 200px;
  margin-left: 16px;
}
