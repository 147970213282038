@import '../../../styles/shared';

.content {
  width: 227px;
  min-height: 213px;
  padding: 16px;
  flex-shrink: 0;
}

.title {
  font-size: 21px;
  color: var(--color-white);
  text-decoration: underline;

  margin-bottom: 42px;

  @include mazars {
    color: var(--color-blue-default);
  }

  @include aes {
    color: var(--color-black);
  }

  @include gandh {
    span {
      color: var(--accent-color);
    }

    position: absolute;
    top: -63px;
    left: 0;
    width: 100%;
    padding-left: 56px;
    color: var(--accent-color);
  }
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-bottom: auto;
}

.row {
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 32px;
  }

  color: var(--statisticRow-color);

  @include gandh {
    color: var(--color-black);
  }
}

.value {
  font-size: 20px;
  font-weight: bold;
}

.schema__color {
  .row:nth-child(1) .value {
    color: #ffa500;
  }

  .row:nth-child(2) .value {
    color: #90d960;
  }

  .row:nth-child(3) .value {
    color: #fc2a42;
  }

  @include gandh {
    .row .value {
      color: var(--color-black);
    }
  }
}
