.modal {
  :global(.rc-dialog-content) {
    padding: 40px;
    border-radius: 8px;
  }
}

.title {
  margin-bottom: 40px;
  font-size: 18px;
  color: var(--light-title-color);
}

.body {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.formControl {
  display: grid;
  grid-template-columns: 110px 260px;
  grid-column-gap: 8px;
  align-items: center;
  margin-bottom: 24px;

  label {
    font-size: 14px;
    color: var(--light-label-color);
  }

  div {
    grid-column-start: 2;
  }
}

.form__controls {
  display: flex;
  gap: 8px;
}
