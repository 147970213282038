@import '../../styles/shared';

.invoice {
  width: 270px;
  min-height: 213px;

  display: flex;
  flex-direction: column;

  padding: 16px 0 8px 0;

  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}

.description {
  padding: 0 8px;
  margin-bottom: auto;
}

.invoice__accept {
  border-left: 8px solid #90d960;
}

.invoice__reject {
  border-left: 8px solid #fc2a42;
}

.invoice__inApproval {
  border-left: 8px solid #f1c35c;
}

.iconWrapper:not(:last-of-type) {
  margin-right: 16px;
}

.attributes {
  display: flex;
  align-items: center;

  overflow-y: hidden;
  overflow-x: auto;

  margin-bottom: 16px;

  @extend %scrollbar;
}

.time {
  color: #313243;
  font-size: 14px;

  margin-bottom: 4px;
}

.name {
  color: #313243;
  font-size: 14px;

  margin-bottom: 8px;
}

.room {
  color: #676883;
  font-size: 12px;

  margin-bottom: auto;
}
