@import '../../../../styles/shared';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px 0 40px;
  border-radius: 4px;
  border: 1px solid #bfc5d2;
  background-color: #fff;
  overflow: hidden;
  transition: 0.15s ease-in-out;

  &:hover {
    border-color: #8798ad;
  }

  &:focus-within {
    border-color: #69707f;
  }

  input[type='search']::-webkit-search-cancel-button {
    appearance: none;
  }
}

.reset__button {
  position: relative;
  width: 12px;
  height: 12px;
  padding: 0;
  opacity: 0.5;
  transition: 0.15s ease-in-out;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 12px;
    border-left: 2px solid currentColor;
    transform: rotate(45deg);
  }

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 12px;
    border-left: 2px solid currentColor;
    transform: rotate(-45deg);
  }

  &:hover {
    opacity: 1;
  }
}

.input {
  width: 100%;
  font-size: 14px;
  color: #2e384d;
  outline: none;
  border: none;
  -webkit-appearance: none;
}

.searchIcon {
  position: absolute;
  left: 8px;
  color: var(--color-black-light);
}
