@import '../../../../styles/shared';

.field {
  border: 1px solid #bfc5d2;
  border-radius: 4px;
  outline: none;

  color: #2e384d;
  font-size: 14px;

  width: 100%;
  height: 40px;

  padding: 0 8px;

  transition: 0.15s ease-in-out;

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    font-size: 14px;
  }

  &.field__invalid,
  &.field__invalid:focus,
  &.field__invalid:hover {
    border-color: var(--color-red);
  }
}
