@import '../../../styles/shared';

.modal :global {
  .rc-dialog-content {
    border-radius: 8px;
  }

  .rc-dialog-close {
    z-index: 1;
    top: 12px;
    right: 18px;
    padding: 0;
  }

  .rc-dialog-body {
    position: relative;
    width: 396px;
  }
}

.title {
  padding: 18px 40px;
  color: #747b8b;
}

.body {
  padding: 40px;
}

.form__controls {
  padding: 24px 40px;
}

.btn {
  padding: 0 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
}

.form__row {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:not(:last-of-type) {
    margin-bottom: 30px;
  }

  & * {
    width: 100%;
  }
}

.error {
  position: absolute;
  bottom: -18px;
  color: var(--color-red);
  font-size: 12px;
}

.validation__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.validation__title {
  font-size: 14px;
  line-height: 21px;
  color: #747b8b;
  margin-bottom: 9px;
}

.validation__message {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: var(--color-grey-medium);

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }

  &__invalid {
    color: var(--color-red);
    fill: var(--color-red);
  }

  &__valid {
    color: var(--color-green-default);
    fill: var(--color-green-default);
  }
}
