@import 'src/styles/shared';

.control {
  position: relative;
}

.mask {
  position: absolute;
  inset: 0;
  background-color: #fff;
  opacity: 0.7;
  z-index: 1;
}

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  color: var(--light-label-color);
}

.errorWrapper {
  position: absolute;
  top: 106%;

  font-size: 12px;
  color: var(--color-red);
}
