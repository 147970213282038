.icon {
  line-height: 1em;
}

.svg {
  vertical-align: top;
}

:global(.icon-spin) {
  animation: loadingCircle 1s infinite linear;
}

@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate270 {
  transform: rotate(-90deg);
}
