@import '../../styles/shared';

.layout {
  display: flex;
  flex-direction: column;

  height: 100vh;
}

.main {
  @extend %scrollbar;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  background: var(--layout-backgroundColor);
}
