@import '../../styles/shared';

.content {
  display: flex;
  align-items: center;

  padding: 0 16px;
}

.time,
.date {
  padding: 0 6px;
}

.time {
  border-left: 1px solid var(--color-white);

  @include ccsc {
    border-color: var(--color-blue-ccsc);
  }

  @include sbi {
    border-color: var(--color-orange-sbi);
  }
}
