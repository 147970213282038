@import '../../styles/shared';

.slider__wrapper {
  position: relative;
}

.logo__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.logo {
  display: block;
  margin: 0 auto;
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 30px;
  right: 30px;
  z-index: 2;
  height: 350px;

  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;

  border-radius: 10px;
  background: var(--menuMask-background);
}

.mask__image {
  border-radius: 10px;

  &:nth-child(1) {
    grid-row: 1 / span 2;

    background: url('../../assets/images/Corona-Hinweis-Gr.jpg') left top;
    background-size: cover;
  }

  &:nth-child(2) {
    background: url('../../assets/images/Corona-Hinweis-Kl1.jpg') left top;
    background-size: cover;
  }

  &:nth-child(3) {
    background: url('../../assets/images/Corona-Hinweis-Kl2.jpg') left top;
    background-size: cover;
  }

  &:nth-child(4) {
    background: url('../../assets/images/Corona-Hinweis-Kl3.jpg') center center;
    background-size: cover;
  }

  &:nth-child(5) {
    background: url('../../assets/images/Corona-Hinweis-Kl4.jpg') left top;
    background-size: cover;
  }
}

.mask__icon {
  margin-bottom: 24px;
}

.slider {
  max-width: 100%;
  margin: 0 35px;
}

.card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  min-width: 150px;
  width: calc(100% - 16px);
  height: 150px;

  margin: 7px 8px 20px;
  border-radius: 10px;
  overflow: hidden;

  cursor: pointer;

  background-image: linear-gradient(
    0deg,
    rgba(#000, 0.5) 0%,
    rgba(#000, 0.1) 100%
  );

  color: white;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  text-align: center;

  transition: box-shadow 0.3s ease;

  &:hover {
    background: rgba(#000, 0.5);
    box-shadow: rgba(#000, 0.4) 0 8px 16px;
  }
}

.cardImage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.cardLabel {
  min-height: 2.5em;
  margin-bottom: 8px;
}
