@import '../../../../styles/shared';

.field {
  background-color: #fbfcfd;

  font-size: 14px;

  border: 1px solid #bfc5d2;
  border-radius: 4px;
  outline: none;

  color: var(--color-white);

  width: 100%;
  height: 40px;
  padding: 12px 8px 11px 8px;

  transition: 0.15s ease-in-out;

  &:-webkit-autofill,
  &:-webkit-autofill:focus {
    font-size: 14px;
  }
}

.field__invalid {
  border-color: var(--color-red);
}

.inputWrapper {
  position: relative;
}

.input {
  -moz-appearance: textfield;

  &::-ms-clear {
    display: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.arrow {
  position: absolute;
  right: 0;
  color: var(--color-black);

  cursor: pointer;
  user-select: none;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
}

.arrow__up {
  top: 5%;
  width: 20px;
  height: 45%;
}

.arrow__down {
  top: 50%;
  width: 20px;
  height: 45%;
}
