@import '../../../styles/shared';

.product__details {
  margin-top: -16px;
  padding: 0 16px;
}

.description {
  color: #70717e;
  font-size: 14px;

  padding: 16px 0;

  &:not(:last-of-type) {
    box-shadow: 0 1px 0 0 rgba(103, 104, 131, 0.24);
  }
}

.column__hidden {
  @include respond-to(tablet) {
    display: none;
  }
}
