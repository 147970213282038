@import '../../styles/shared';

.page {
  display: grid;
  grid-template-columns: 364px 1fr;
  flex-grow: 1;
  overflow: auto;
}

.navigation {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.navigationRow {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 96px;
  padding: 24px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.title {
  font-size: 21px;
  font-weight: bold;
}

.aside__data {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 34px;
}

.roomInfo {
  padding-bottom: 30px;
  margin-bottom: 24px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.roomTitle {
  font-size: 18px;
  font-weight: bold;
}

.duration {
  font-size: 14px;
}

.roomInfoLine {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .icon {
    margin-right: 12px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.menu {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @extend %scrollbar;
}

.emptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  text-align: center;
  margin: auto 0;
  width: 140px;
  font-size: 18px;
  line-height: 22px;

  .text {
    opacity: 0.48;
  }
}

.emptyState__icon {
  margin-bottom: 24px;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-shrink: 0;
  height: 96px;
  padding: 24px 34px 0;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}

.products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(146px, 1fr));
  grid-column-gap: 18px;
  grid-row-gap: 16px;
  padding: 34px;
  min-width: 50%;
}

.icon {
  color: var(--color-white);

  &__arrow {
    font-size: 24px;
  }

  @include aes {
    color: var(--color-black);
  }
}

// ---------------
.summary__wrapper {
  transform: translateY(364px);
  transition: transform 0.3s ease;

  &.visible {
    transform: translateY(0);
  }
}

.summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 34px;
  box-shadow: 0 -1px 0 0 var(--color-box-shadow-line);
}

.summary__info {
  display: flex;
  flex-direction: column;
}

.summary__title {
  margin-bottom: 2px;
  font-size: 12px;
  color: var(--color-grey-medium);
}

.summary__total {
  font-size: 18px;
  font-weight: bold;
}

// --------------------
