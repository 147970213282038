@import '../../styles/shared';

.header {
  display: flex;
  flex-direction: column;
  padding: 40px 34px 0 90px;
  background: url('../../assets/images/bg-booking.svg') no-repeat right -60px,
    #e1e4e8;

  @include aes {
    background: #ffffff;
  }
}

.header__info {
  position: relative;
  display: flex;
  flex-grow: 1;
  gap: 40px;
  margin-bottom: 64px;
}

.eventData {
  display: flex;
  gap: 40px;
}

.content {
  display: flex;
  flex-grow: 1;
  padding: 34px 34px 0 90px;
}

.btn__editMenu {
  margin-bottom: 40px;
  width: max-content;
}

.information,
.panel {
  width: 50%;
}

.information {
  position: relative;
  display: flex;
  flex-direction: column;
}

.property:not(:last-of-type) {
  margin-bottom: 40px;
}

.data {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
}

.name {
  color: #333333;
  font-size: 22px;
  font-weight: bold;
  overflow-wrap: break-word;
  word-break: break-word;

  margin-bottom: 8px;
}

.date {
  font-size: 14px;
  color: #333333;
}

.invoice {
  display: flex;
  align-items: center;
  color: #676883;
  font-size: 14px;

  &__reject {
    color: var(--color-red);
  }
}

.icon__bill {
  color: #676883;

  width: 24px;
  height: 24px;

  margin-right: 8px;
}

.icon__attention {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.status {
  min-width: 196px;
  height: 48px;
}

.propertyTitle {
  font-size: 16px;
  color: #333333;
  font-weight: bold;

  margin-bottom: 8px;
}

.link {
  color: var(--color-blue-light);
  padding-left: 5px;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
}

.reasonTitle {
  color: var(--color-red);
}

.propertyValue {
  font-size: 14px;
  color: #232323;
}

.details__title {
  margin-bottom: 12px;
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.details__file {
  display: flex;
  font-size: 14px;
  color: #232323;
}

.icon__pdf {
  margin-right: 15px;
  font-size: 48px;
}

.btn__viewFile {
  padding: 0;
  color: var(--color-grey-medium);
  text-decoration: underline;

  &:hover {
    color: var(--color-red-strong);
  }
}

.price {
  display: inline-flex;
  align-items: center;
  height: 48px;
  margin-left: auto;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
}

.total {
  margin-right: 16px;
}

.controls {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex-shrink: 0;
}

.btn:not(:last-of-type) {
  margin-right: 14px;
}

.btn__arrow {
  position: absolute;
  left: -58px;
  width: 48px;
  height: 48px;
  padding: 0;
  font-size: 24px;
}

.status {
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0 15px;

  color: var(--color-white);

  border-radius: 2px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);

  &__accepted,
  &__finished {
    background-color: var(--primary-color);

    @include mazars {
      background-color: var(--color-teal-default);
    }

    @include gandh {
      background-color: var(--color-green-default);
    }
  }

  &__rejected,
  &__closed {
    background-color: var(--color-red);
  }

  &__processing {
    background-color: var(--color-yellow-medium);
  }
}

.icon__status {
  margin-right: 16px;
  font-size: 24px;
}
