@import '../../styles/shared';

.grid__booking {
  flex-grow: 1;

  :global(.grid-row) {
    display: grid;
    grid-template-columns: 24% 1fr 24% 12%;
  }

  :global(.column) {
    word-break: break-word;
    overflow-wrap: break-word;
  }
}

.grid__invoice {
  flex-grow: 1;

  margin-right: 16px;

  :global(.grid-row) {
    display: grid;
    grid-template-columns: 20% 40% 20% 1fr;
  }

  :global(.title) {
    color: #b6b7cb;

    transition: color 0.3s ease;

    &:hover {
      color: #313243;
    }
  }

  @include respond-to(laptop) {
    width: 50%;
  }
}

.navigation {
  background: url('../../assets/images/bg-booking.svg') right -60px no-repeat,
    linear-gradient(180deg, #a82837, #e2001a);

  @include mazars {
    background: linear-gradient(180deg, #f3f6f9 0%, #ffffff 100%) 0 0 no-repeat;
  }

  @include aes {
    background: linear-gradient(to bottom, #f3f6f9, #ffffff);
  }

  @include gandh {
    background: var(--color-white);
  }

  @include ccsc {
    background: var(--color-blue-ccsc);
  }

  @include sbi {
    background: var(--color-orange-sbi);
  }

  min-height: 230px;
}

.filters {
  display: flex;
  align-items: center;

  padding: 22px 0 30px 0;

  color: var(--color-white);

  @include aes {
    color: var(--color-black);
  }

  @include gandh {
    color: var(--color-black);
  }
}

.content {
  padding: 0 34px 34px 34px;

  flex-grow: 1;

  display: flex;
  justify-content: space-between;
}

.content__events,
.content__invoice {
  display: flex;
  flex-direction: column;

  width: 100%;

  margin-top: -122px;
}

.wrapper__invoice {
  display: flex;

  height: 100%;
}

.row {
  display: flex;

  &:nth-child(1) {
    min-height: 108px;

    align-items: flex-end;

    padding: 0 100px;

    box-shadow: 0 1px 0 0 rgba(#f3f6f9, 0.24%);
  }
}

.icon {
  font-size: 48px;

  margin-right: 24px;
}

.link {
  position: relative;
  display: flex;
  align-items: center;

  min-height: 74px;
  padding: 8px 8px 18px 8px;

  color: var(--color-white);

  @include mazars {
    color: var(--color-blue-default);
  }

  @include aes {
    color: var(--color-black);
  }

  @include gandh {
    color: var(--color-black);
  }

  opacity: 0.5;

  &__active {
    opacity: 1;
    box-shadow: inset 0 -4px 0 0 var(--color-white);

    @include mazars {
      box-shadow: inset 0 -4px 0 0 var(--color-blue-default);
    }

    @include aes {
      box-shadow: inset 0 -4px 0 0 var(--color-red-strong);
    }

    @include gandh {
      box-shadow: inset 0 -4px 0 0 var(--accent-color);
    }
  }

  &__middle {
    margin: 0 auto;
  }
}

.select {
  min-width: 200px;
  margin-left: 16px;
}

.notificationCount {
  position: absolute;
  top: 0;
  left: 38px;

  width: 32px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background-color: var(--color-red);

  @include mazars {
    color: var(--color-white);
  }

  @include aes {
    color: var(--color-white);
    background-color: var(--color-red-strong);
  }

  @include gandh {
    background-color: var(--accent-color);
    color: var(--color-white);
  }
}

.panel {
  display: flex;
  flex-direction: column;

  width: 50%;
}

.panel__invoice {
  max-width: 608px;
}

.grid__booking,
.grid__invoice :global(.body),
.panel__invoice {
  @extend %scrollbar;

  overflow: auto;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);

  // 100vh - filters - tabs - header - padding
  height: calc(100vh - 90px - 108px - 60px - 34px);
}

.grid__invoice :global(.body) {
  // 100vh - filters - tabs - header - padding - caption
  height: calc(100vh - 90px - 108px - 60px - 34px - 32px);
}

.panel__invoice {
  flex-shrink: 0;
}

.panel__invoice :global(.controls) {
  position: sticky;
  top: 0;
}

.grid__totalPrice {
  font-weight: 500;
}
