@import '../../../styles/shared';

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.buttonArrow {
  padding: 0;
}

.icon {
  margin-right: 16px;

  &__arrow {
    font-size: 24px;
  }

  color: var(--light-icon-color);
}

.panel {
  display: flex;
  flex-direction: column;

  margin-right: auto;

  color: rgba(255, 255, 255, 0.5);
}

.row {
  display: flex;
  align-items: flex-end;

  margin-bottom: 11px;
}

.bookingName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 21px;
  font-weight: bold;

  color: var(--light-title-color);
}

.counter {
  display: flex;
  align-items: center;

  @include mazars {
    color: var(--color-black-light);
  }

  @include aes {
    color: var(--color-black-light);
  }
}

.stepName,
.currentStep {
  margin-right: 5px;

  @include sionum {
    color: var(--color-white);
  }
}

.steps {
  display: flex;
}

.step {
  cursor: pointer;

  @include mazars {
    color: var(--color-grey-medium);
  }

  @include aes {
    color: var(--color-grey-medium);
  }

  &__disabled {
    cursor: auto;
    pointer-events: none;
  }

  &__active {
    color: var(--color-white);

    @include mazars {
      color: var(--color-blue-light);
    }

    @include aes {
      color: var(--color-red-strong);
    }
  }

  &:not(:last-of-type) {
    margin-right: 10px;
  }
}
