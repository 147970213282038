@import '../../styles/shared';

$duration: 0.3s;

.switch {
  &:global(.rc-switch) {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 44px;
    height: 22px;
    line-height: 20px;
    padding: 0;
    vertical-align: middle;
    border-radius: 20px 20px;
    border: 1px solid rgba(#000000, 0.16);
    background-color: rgba(#000000, 0.16);
    cursor: pointer;
    transition: all $duration cubic-bezier(0.35, 0, 0.25, 1);

    &:after {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 2px;
      top: 1px;
      border-radius: 50% 50%;
      background-color: var(--color-white);
      content: ' ';
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      transform: scale(1);
      transition: left $duration cubic-bezier(0.35, 0, 0.25, 1);
      animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      animation-duration: $duration;
      animation-name: rcSwitchOff;
    }

    &:hover:after {
      transform: scale(1.1);
      animation-name: rcSwitchOn;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(#2db7f5, 80%);
      outline: none;
    }
  }

  &:global(.rc-switch-inner) {
    color: var(--color-white);
    font-size: 12px;
    position: absolute;
    left: 24px;
    top: 0;
  }

  &:global(.rc-switch-checked) {
    border: 1px solid var(--primary-color);
    background-color: var(--primary-color);
    $this: &;

    #{$this}-inner {
      left: 6px;
    }

    &:after {
      left: 22px;
    }

    @include mazars {
      border: 1px solid var(--color-teal-default);
      background-color: var(--color-teal-default);
    }
  }

  &:global(.rc-switch-disabled) {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;

    &:after {
      background: #9e9e9e;
      animation-name: none;
      cursor: no-drop;
    }

    &:hover:after {
      transform: scale(1);
      animation-name: none;
    }
  }

  &:global(.rc-switch-label) {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    user-select: text;
  }
}

@keyframes rcSwitchOn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes rcSwitchOff {
  0% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
