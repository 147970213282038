@import '../../styles/shared';

@keyframes rcDialogZoomIn {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

@keyframes rcDialogZoomOut {
  0% {
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}

@keyframes rcDialogFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rcDialogFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:global {
  .rc-dialog-wrap {
    @extend %scrollbar;

    overflow: auto;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;

    -webkit-overflow-scrolling: touch;

    outline: 0;

    display: flex;
  }

  .rc-dialog-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgb(55, 55, 55);
    background-color: rgba(55, 55, 55, 0.6);
    filter: alpha(opacity=50);

    height: 100%;
    z-index: 1050;

    &-hidden {
      display: none;
    }
  }

  .rc-dialog-content {
    position: relative;

    border-radius: 40px;

    overflow: hidden;

    background-color: var(--color-white);
  }

  .rc-dialog-close {
    cursor: pointer;
    border: none;
    outline: none;
    background: transparent;

    font-size: 20px;
    color: #000;

    position: absolute;
    top: 16px;
    right: 16px;

    margin: 0;
    padding: 0;
    width: 24px;
    height: 24px;

    opacity: 0.4;

    &:hover {
      opacity: 0.8;
    }

    &-x:after {
      position: absolute;
      content: '';
      top: 5px;
      left: 11px;
      height: 14px;
      border-left: 2px solid #000;
      transform: rotate(45deg);
    }

    &-x:before {
      position: absolute;
      content: '';
      top: 5px;
      left: 11px;
      height: 14px;
      border-left: 2px solid #000;
      transform: rotate(-45deg);
    }
  }

  .rc-dialog-close-header {
    padding: 13px 20px 14px 20px;
    border-radius: 5px 5px 0 0;
    background: var(--color-white);
    color: #666;
    border-bottom: 1px solid #e9e9e9;
  }

  .rc-dialog-close-footer {
    border-top: 1px solid #e9e9e9;
    padding: 10px 20px;
    text-align: right;
    border-radius: 0 0 5px 5px;
  }
}

:global(.rc-dialog) {
  margin: auto;
  align-self: center;
}

@mixin fade-effect {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
}

:global(.rc-dialog-fade-enter),
:global(.rc-dialog-fade-appear) {
  @include fade-effect;
  opacity: 0;
  animation-play-state: paused;
}

:global(.rc-dialog-fade-leave) {
  @include fade-effect;
  animation-play-state: paused;
}

:global(.rc-dialog-fade-enter#{&}-fade-enter-active),
:global(.rc-dialog-fade-appear#{&}-fade-appear-active) {
  animation-name: rcDialogFadeIn;
  animation-play-state: running;
}

:global(.rc-dialog-fade-leave#{&}-fade-leave-active) {
  animation-name: rcDialogFadeOut;
  animation-play-state: running;
}

@mixin zoom-effect {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

:global(.rc-dialog-zoom-enter),
:global(.rc-dialog-zoom-appear) {
  @include zoom-effect;

  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

:global(.rc-dialog-zoom-leave) {
  @include zoom-effect;

  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

:global(.rc-dialog-zoom-enter#{&}-zoom-enter-active),
:global(.rc-dialog-zoom-appear#{&}-zoom-appear-active) {
  animation-name: rcDialogZoomIn;
  animation-play-state: running;
}

:global(.rc-dialog-zoom-leave#{&}-zoom-leave-active) {
  animation-name: rcDialogZoomOut;
  animation-play-state: running;
}

@media (min-width: 768px) {
  :global(.rc-dialog) {
    margin: 30px auto;
  }
}

.modal__condition {
  :global(.rc-dialog-content) {
    width: 475px;
    padding: 40px;

    border-radius: 4px;
  }

  .text {
    margin-bottom: 25px;

    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    color: #232323;

    word-break: break-word;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;

    margin-top: 50px;
  }

  .btn {
    font-weight: bold;
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }

  .label {
    margin-bottom: 25px;
    padding-right: 20px;

    font-size: 18px;
    line-height: 22px;
    color: rgba(#232323, 0.5);
  }

  .input {
    border: none;
    border-bottom: 1px solid #b6b7cb;
    outline: none;

    color: #b6b7cb;
    font-size: 14px;

    width: 100%;
    height: 1.5em;

    transition: 0.15s ease-in-out;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      font-size: 14px;
    }
  }
}
