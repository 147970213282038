.select {
  width: 100%;
}

.noOptionsMessage {
  text-align: center;
  padding: 16px 12px;
}

.text {
  margin-bottom: 24px;
}

.message {
  margin-bottom: 12px;
}
