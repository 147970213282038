.summary__roomInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 104px;
    width: 1px;
    background-color: var(--color-box-shadow-line);
  }
}

.summary__row {
  display: grid;
  align-items: center;
  grid-template-columns: 24px 1fr 124px;
  grid-column-gap: 16px;
  margin-bottom: 24px;
}

.row__catering {
  margin-top: 10px;
}

.summary__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.roomRow {
  grid-column: span 2;
}

.btnInfo {
  padding: 0;
}

.summary__product {
  display: grid;
  grid-template-columns: 1fr 124px;
  grid-column-gap: 16px;
  grid-column: 2 / 4;
}

.summary__productInfo {
  display: grid;
  grid-template-columns: 1fr 100px 60px;
  box-shadow: 0 1px 0 0 var(--color-box-shadow-line);
  font-size: 14px;

  & ~ .summary__totalValue {
    font-size: 14px;
    padding: 8px 16px 8px 34px;
  }
}

.summary__productInfoCell {
  padding: 8px;
}

.summary__totalValue {
  align-self: start;
  padding: 10px 16px 10px 34px;
}

.textarea {
  width: 100%;
  min-height: 40px;
  padding: 10px 8px;
  font-size: 14px;
  color: #2e384d;
  background-color: #fbfcfd;
  border-radius: 4px;
  border: 1px solid #bfc5d2;
  outline: none;
  resize: none;
  overflow: hidden;
  transition: 0.15s ease-in-out;

  &.textareaError {
    border-color: var(--color-red);
  }
}
