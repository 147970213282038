@import '../../../styles/shared';

.modal__description {
  :global(.rc-dialog-content) {
    position: relative;

    overflow: hidden;

    width: 604px;
    padding: 0 50px;

    background-color: transparent;
  }

  :global(.slick-list) {
    border-radius: 8px;
  }

  :global(.slick-slider) {
    margin: 0;
    padding: 0;
  }

  :global(.slick-slide) {
    overflow: hidden;

    background-color: var(--color-white);
  }

  :global(.slick-arrow) {
    display: flex !important;
    justify-content: center;
    align-items: center;

    width: 48px;
    height: 48px;
  }

  :global(.slick-arrow.slick-prev) {
    left: -50px;
  }

  :global(.slick-arrow.slick-next) {
    right: -50px;
  }

  :global(.rc-dialog-close) {
    width: 24px;
    height: 24px;

    font-size: 24px;

    top: 8px;
    right: 58px;

    z-index: 1;
  }
}

.wrapper__description {
  display: flex !important;

  & .product {
    width: 146px;
    min-height: 320px;
    border-radius: 0;
    border: none;
    box-shadow: none;
    flex-shrink: 0;
  }
}

.wrapper__details {
  flex-grow: 1;
  min-width: 1px;
  padding: 16px;
}

.detail:not(:last-of-type) {
  margin-bottom: 24px;
}

.content {
  color: #71727f;
  font-size: 14px;

  ul,
  p {
    margin-bottom: 12px;
  }

  ul {
    line-height: 18px;
    list-style: disc inside;
  }

  a {
    display: block;
    margin-bottom: 8px;

    text-decoration: underline;
    color: var(--color-matterhorn);
    opacity: 0.6;
    transition: opacity 500ms ease;

    &:hover {
      opacity: 1;
    }
  }
}

.detail__title {
  color: #71727f;
  font-size: 14px;
  margin-bottom: 8px;
}

.title {
  font-size: 16px;
  color: #71727f;
  font-weight: bold;

  margin-bottom: 16px;
}

.allergens {
  display: flex;
  flex-wrap: wrap;
}

.allergen__icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 8px 0;

  border-radius: 50%;
  background: #fff;
  filter: drop-shadow(-4px 2px 2px var(--color-box-shadow));
}

.product {
  box-shadow: none;
  border: none;
  border-radius: 0;

  width: 146px;
  flex-shrink: 0;
  min-height: 292px;
}
