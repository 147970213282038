.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.error {
  position: absolute;
  top: 106%;
  font-size: 12px;
  color: var(--color-red);
}
