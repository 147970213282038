@import '../../styles/shared';

.slider {
  margin: 0 35px;
  padding: 0 35px;

  & :global(.slick-track) {
    display: flex;
    justify-content: center;
  }

  & :global(.slick-arrow) {
    color: white;
    font-size: 20px;

    @include aes {
      color: var(--color-black);
    }

    &:before {
      content: none;
    }
  }

  & :global(.slick-prev) {
    left: -10px;
  }

  & :global(.slick-next) {
    right: -10px;
  }

  & :global(.slick-dots) {
    bottom: -40px;

    & button:before {
      font-size: 12px;
      color: white;
      opacity: 0.25;

      @include aes {
        color: var(--color-black);
      }
    }

    & :global(.slick-active) button:before {
      color: white;
      opacity: 1;

      @include aes {
        color: var(--color-black);
      }
    }
  }

  & :global(.slick-slide) > div {
    height: 50%;
  }
}
